'use strict';

function disableFileSubmit() {
    $('form.contactus-info-form').spinner().start();
    $('.contactus-info-submit').prop('disabled', true);
}
function enableFileSubmit() {
    $('.contactus-info-submit').prop('disabled', false);
}

$(document).ready(function () {
    function validDescription() {
        var description = $('.cus-description').val();
        if (description === '') {
            $('.cus-description').addClass('is-invalid');
        } else {
            $('.cus-description').removeClass('is-invalid');
        }
    }

    function clearFormFile() {
        $('#fileInput, #fileName, #fileData').val('');
        $('.file-name').text('No document uploaded');
        $('.invalid-uploadSize').empty();
        $("span.add-file").removeClass('d-none').addClass('d-flex');
        $("span.delete-file").removeClass('d-flex').addClass('d-none');
    }

    function clearFormErrors() {
        $('form.contactus-info-form input, form.contactus-info-form .form-control').removeClass('is-invalid');
        $('form.contactus-info-form .invalid-feedback').empty().hide();
    }

    $('.contactus-info-submit').click(function (e) {
        validDescription();
    });
    $('.cus-description').change(function () {
        validDescription();
    });

    $('#fileInput').on('change', function (e) {
        var file = e.target.files[0];
        if (file) {
            var reader = new FileReader();
            reader.onload = function (event) {
                var fileName = file.name;
                var fileData = event.target.result.split(',')[1];
                var fileSize = file.size;
                $('#fileName').val(fileName);
                $('#fileData').val(fileData);
                $('#fileSize').val(fileSize);
                $('.file-name').text(fileName);
                $('.invalid-uploadSize').empty();
                $("span.add-file").removeClass('d-flex').addClass('d-none');
                $("span.delete-file").removeClass('d-none').addClass('d-flex');
            };
            reader.readAsDataURL(file);
        }
    });

    $('.delete-file-reset').on('click', function () {
        clearFormFile();
    });

    $('form.contactus-info-form').submit(function (e) {
        e.preventDefault();
        disableFileSubmit();
        var fileInput = document.getElementById('fileInput');
        var form = $(this);
        var button = $('.contactus-info-submit');
        var url = form.attr('action');
        var formData = $(this).serialize();
        if (fileInput.files.length !== 0) {
            var file = fileInput.files[0];
            var fileSize = file.size / 1024 / 1024; // in MB
            if (fileSize > 4) {
                enableFileSubmit();
                $.spinner().stop();
                $('.invalid-uploadSize').html('File size exceeds 4 MB, please select a smaller file.');
                $('.invalid-uploadSize').show();
                $(file).val('');
                return false;
            }
            formData += '&fileName=' + $('#fileName').val();
            formData += '&fileData=' + $('#fileData').val();
            formData += '&fileSize=' + $('#fileValue').val();
        }
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: formData,
            success: function (data) {
                enableFileSubmit();
                $.spinner().stop();
                if (data.success) {
                    form.trigger('reset');
                    $('.contactus-info-form').hide();
                    $('.contact-us-message').empty().append('<div class="pt-5 mt-5"><i class="fa fa-check-circle fa-5x text-success"></i></div><div class="pt-3 text-center"><h5 class="text-success">' + data.msgh + '</h5><h5 class="text-success">' + data.msg + '</h5></div>').show();
                    $('.contactus-modal').addClass('form-submitted');
                } else if (data.error) {
                    $('#contactemail').addClass('is-invalid');
                    $('#contactemail').next('.invalid-feedback').empty().append(data.msg);
                } else {
                    $('.contact-us-message').empty().append('<div class="pb-3"><i class="fa fa-times-circle fa-3x text-danger"></i></div><div class="pt-1 text-center"><h5 class="text-danger">' + data.msg + '</h5></div>').show();
                }
                button.attr('disabled', false);
            },
            error: function (err) {
                $.spinner().stop();
                button.attr('disabled', false);
            }
        });
        return true;
    });

    $('.close-contactUs').on('click', function (e) {
        var isValue = false;
        $('form.contactus-info-form').find('input[type=text]:required, input[type=email]:required, select:required, textarea:required, input[type=radio]:checked').each(function () {
            if ($(this).val().trim() !== '') {
                isValue = true;
            }
        });
        if (isValue) {
            $('form.contactus-info-form').spinner().start();
            $('#contactUsconfirmationModal').modal('show');
            e.preventDefault();
        } else {
            clearFormErrors();
            $('#contactusModal').modal('hide');
        }
        $('#confirmYes').click(function () {
            $('.contactus-info-form')[0].reset();
            clearFormErrors();
            clearFormFile();
            $('.contact-us-message').empty();
            $('.contactus-info-form').show();
            $('#contactUsconfirmationModal,#contactusModal').modal('hide');
        });
        $('#contactUsconfirmationModal').on('hide.bs.modal', function () {
            $('form.contactus-info-form').spinner().stop();
        });
        $('#contactusModal').on('hidden.bs.modal', function () {
            $('form.contactus-info-form').trigger('reset');
            $('.contact-us-message').empty();
            $('.contactus-info-form').show();
        });
    });
});

